const CommonCore = (() => {

	class Core {

		constructor() {

			this.storageColorSheme = localStorage.getItem('color-scheme') || 'dark';
			this.groupTriggers 	   = document.querySelector('.group-triggers');
			this.triggerDarkTheme  = this.groupTriggers.querySelector('.dark-theme');
			this.sidebarMain 	   = document.querySelector('.sidebar');
			
			this.funcClickByArea = (event) => {

                let target = event.target;

                if (target.hasAttribute('data-sidebar-close') == true) {
                    
					this.sidebarMain.classList.remove('--show');
					document.body.classList.remove('body-overlay');
					document.body.removeAttribute('data-sidebar-close');

                    document.removeEventListener('click', this.funcClickByArea, false);

                }

            }

		}


		init() {
			
			this.events();
			this.others();


			if (this.storageColorSheme == 'dark') {

				this.triggerDarkTheme.querySelector('input').setAttribute('checked', true);				
				
			} else {
				
				this.triggerDarkTheme.querySelector('input').removeAttribute('checked');				

			}

		}


		events() {

			this.groupTriggers.addEventListener('click', this.listener_group_triggers.bind(this), false);
			
			if (this.sidebarMain) {

				this.sidebarMain.addEventListener('click', this.listener_sidebar.bind(this), false);

			}

		}


		listener_group_triggers(event) {

			let target = event.target;

			
			// dark-theme
			if (target.closest('.dark-theme') !== null) {

				let result = (event.target.checked == true) ? 'dark' : 'light';

				localStorage.setItem('color-scheme', result);
				document.documentElement.setAttribute('data-color-scheme', result);
				document.querySelector('meta[name="color-scheme"]').setAttribute('content', result);

			}


			// sidebar-hamburger
			if (target.closest('.sidebar-hamburger') !== null) {

				this.sidebarMain.classList.add('--show');
				document.body.classList.add('body-overlay');
				document.body.setAttribute('data-sidebar-close', true);
				
				document.addEventListener('click', this.funcClickByArea, false);
			
			}

		}


		listener_sidebar(event) {

			let target = event.target;

			if (target.tagName == 'A' || target.hasAttribute('data-sidebar-close') == true) {

				this.sidebarMain.classList.remove('--show');
				document.body.classList.remove('body-overlay');
				document.body.removeAttribute('data-sidebar-close');
				document.removeEventListener('click', this.funcClickByArea, false);

			}

		}


		others() {

			let today = new Date(),
				currentYear = document.querySelector('.js-current-year') || false;

			
			if (currentYear !== false) {

				currentYear.textContent = today.getFullYear();

			}

		}

	}


	let active = null;


	const init = () => {

		try {

			active = new Core();
			active.init();
			
			return active;
			
		} catch (error) {

			console.error(`#CommonCore: ${error.message}`);
			
		}

	};


	return { init };

})()


window.CommonCore = CommonCore;


export { CommonCore };