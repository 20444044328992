const PartialsAuthor = (() => {

    class Core {

        constructor() {

            this.author = document.querySelector('.author') || false;
            
            if (this.author !== false) {

                this.random_color();

            }

        }


        random_color() {

            let MainRandom = () => (Math.random().toString(16) + '000000').substring(2,8).toUpperCase();
            
		    this.author.setAttribute(
                'style',                
                `--color-author: linear-gradient(45deg, 
                    #${MainRandom()} 0%, 
                    #${MainRandom()} 100%);`
            );
        
        }

    }


    let active = null;


    const init = () => {

        return active = new Core();

    };


    return { init }

})()


window.PartialsAuthor = PartialsAuthor;


export { PartialsAuthor }