const PartialsFormBuild = (() => {

	class Core {

		constructor() {

			this.form = document.querySelector('.form-build') || false;

			if (this.form !== false) this.build();

		}


		build() {

			this.form.addEventListener('submit', this.listener_form.bind(this), false);

		}


		listener_form(event) {

			event.preventDefault();


			let data     = [],
			    result   = null,
				xhr      = new XMLHttpRequest(),
			    children = Array.from(this.form.querySelectorAll('input[type="checkbox"]'));


			children.forEach((variable) => {

				if (variable.checked == true) {

					data.push({
						name:     variable.value,
						nameFunc: variable.getAttribute('data-name-func'),
						path: {
							style:  variable.getAttribute('data-name-path-style'),
							script: variable.getAttribute('data-name-path-script'),
						}
					});

				}

			});


			result = JSON.stringify(data);


			if (data.length > 0) {

				BasicPreloader.show('#form-build');

				// https://api.artkbn.com/basic-ui/build
				xhr.open('POST', `http://127.0.0.1:7000/basic-ui/build`);
				xhr.setRequestHeader('Content-Type', 'application/json');

				xhr.onerror = () => {

					BasicPreloader.hide('#form-build');
					alert(`Произошла ошибка, давайте попробуем ещё раз`);

				};

				xhr.onload = () => {

					if (xhr.status != 200) {

						alert(`Произошла ошибка, давайте попробуем ещё раз`);

					} else {

						let anchor = document.createElement('a');

						anchor.href = xhr.response;
						anchor.download = 'download';
						anchor.click();

					}


					BasicPreloader.hide('#form-build');

				};

				xhr.send(result);

			} else {

				alert(`Выберите что-нибудь из списка`);

			}

		}

	}


	let active = null;


    const init = () => {

        return active = new Core();

    };


    return { init }

})()


window.PartialsFormBuild = PartialsFormBuild;


export { PartialsFormBuild }
