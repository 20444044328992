// libs and common
import './modules/libs/prism.js';
import './modules/methods.js';

import { CommonCore }        from './modules/core';
import { BasicPreloader }    from './modules/ui/preloader.js';
import { PartialsAuthor }    from './modules/partials/author';
import { PartialsFormBuild } from './modules/partials/form-build';


// init
document.addEventListener('DOMContentLoaded', () => {

    CommonCore.init();
	BasicPreloader.init();
    PartialsAuthor.init();
	PartialsFormBuild.init();

});
