const BasicPreloader = (() => {

	class Core {

		constructor(el, params) {

			this.el = el;
			this.params = {
				timeout: params.timeout || false,
				content: params.content || `
					<div class="preloader-spinner">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>`,

			};

			this.build();

		}


		build() {

			let wrapper   = document.createElement('div'),
				container = document.createElement('div');


			container.innerHTML = this.params.content;
			container.classList.add('preloader-container');

			wrapper.classList.add('preloader-wrapper');
			wrapper.innerHTML = container.outerHTML;


			this.elemLoader = wrapper.outerHTML;

		}


		method_show() {

			this.el.classList.add('preloader--show');
			this.el.insertAdjacentHTML('beforeend', this.elemLoader);


			// timeout
			if (this.timeout) {

				setTimeout(() => this.method_hide(), this.timeout);

			}

		}


		method_hide() {

			this.el.classList.remove('preloader--show');
			this.el.querySelector('.preloader-wrapper').remove();

		}

	};


	let active = null;


	const init = (variable, config) => {

		let element  = null,
			elements = CommonMethod.variables(variable, '.js-preloader'),
			options  = config || {};


		try {

			if (elements == false && variable !== undefined) throw new Error(CommonMethod.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(CommonMethod.logging['error']['type']);

			elements.forEach(el => {

				element = el;

				active = new Core(element, options);
				active.method_show();

			});

			return active;

		} catch(error) {

			console.error(`${CommonMethod.logging['name']} Preloader init. \nMessage: ${error.message} \nElement: `, element);

		}

	}


	const show = (variable, config) => {

		let element  = null,
			elements = CommonMethod.variables(variable, '.js-preloader:not(.preloader-init)'),
			options  = config || {};


		try {

			if (elements == false && variable !== undefined) throw new Error(CommonMethod.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(CommonMethod.logging['error']['type']);

			elements.forEach(el => {

				element = el;

				active = new Core(element, options);
				active.method_show();

			});

			return active;

		} catch(error) {

			console.error(`${CommonMethod.logging['name']} Preloader show. \nMessage: ${error.message} \nElement: `, element);

		}

	}


	const hide = (variable, config) => {

		let element  = null,
			elements = CommonMethod.variables(variable, '.js-preloader:not(.preloader-init)'),
			options  = config || {};


		try {

			if (elements == false && variable !== undefined) throw new Error(CommonMethod.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(CommonMethod.logging['error']['type']);

			elements.forEach(el => {

				element = el;

				active = new Core(element, options);
				active.method_hide();

			});

			return active;

		} catch(error) {

			console.error(`${CommonMethod.logging['name']} Preloader hide. \nMessage: ${error.message} \nElement: `, element);

		}

	}


	return { init, show, hide };

})();


window.BasicPreloader = BasicPreloader;


export { BasicPreloader };
